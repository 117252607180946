@import './Components/Map/Map.scss';

::backdrop {
    z-index:0;  
    background-color: white !important;
}

.Chart-Right {
    background-color: white;

    canvas#myCanvas-Bar {
        background-color: white;
    }

    #myCanvas-Water {
        padding-left: 9%;
        width: 75%;
    }

    .content-carousel {
        height: 100%;
    }

    .top {
        height: 14%;
    }

    .title {
        color: #606060;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-bottom: 5px;
        padding-top: 10px;
        font-weight: bold;
    }

    .content {
        height: 80%;
        padding: 0 20px;
    }

    .bot {
        height: 8%;
    }
    .title {
        position: relative;
    }
    .btn-fs {
        position: absolute;
        right: 20px;
        top: 5px;
    }
}

.Camera {
    .title {
        color: #606060;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-bottom: 5px;
        padding-top: 10px;
        font-weight: bold;
    }

    .top {
        height: 10%;
    }

    .content {
        height: 85%;
    }

    .bot {
        height: 5%;
    }

    img {
        border: 1px solid;
    }
}

.Statistical-hour {
    .title {
        color: #606060;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-bottom: 5px;
        padding-top: 10px;
        font-weight: bold;
    }

    .total {
        color: #34A853;
        font-weight: bold;
        line-height: 30px;
    }
}

.Statistical-day {
    .title {
        color: #606060;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-bottom: 5px;
        padding-top: 10px;
        font-weight: bold;
    }

    .total {
        color: #4285F4;
        font-weight: bold;
        line-height: 30px;
    }
}

.DashBoardDOT {
    .list-chart {
        height: calc(100vh - 70px);
        overflow: auto;
        .chart-container {
            border-left: 2px solid;
            border-top: 2px solid;
            border-bottom: 4px solid;
            border-right: 2px solid;;
            color: #0000001f;
            border-radius: 5px;
            margin: 10px;
            p {
                text-align: center;
                color: #606060;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                margin-bottom: 5px;
                padding-top: 10px;
                font-weight: bold;
                margin: 0;
                padding-top: 10px;
            }
            .chart-item {
                padding: 0 20px;
                height: 300px;
            }
        }
    }
    @media (min-width: 1200px) {
        .Map-Right {
            height: calc(64vh - 70px) !important;
        }
        .Map-Right-Container {
            height: calc(64vh - 71px) !important;
        }
        .Statistical-hour, .Statistical-day {
            margin: 10px 0;
            height: calc(18vh - 20px) !important;
            border: 2px solid;
            border-bottom: 4px solid;
        }
    }
}

.DashBoardAC {
    .water-section {
        border-left: 2px solid;
            border-top: 2px solid;
            border-bottom: 4px solid;
            border-right: 2px solid;;
            color: #0000001f;
            border-radius: 5px;
            margin: 10px;
        canvas {
            width: 100%;
        }
    }
    .list-chart {
        height: calc(100vh - 70px);
        overflow: auto;
        .chart-container {
            border-left: 2px solid;
            border-top: 2px solid;
            border-bottom: 4px solid;
            border-right: 2px solid;;
            color: #0000001f;
            border-radius: 5px;
            margin: 10px;
            p {
                text-align: center;
                color: #606060;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                margin-bottom: 5px;
                padding-top: 10px;
                font-weight: bold;
                margin: 0;
                padding-top: 10px;
            }
            .chart-item {
                padding: 0 20px;
                height: 300px;
            }
        }
    }
    @media (min-width: 1600px) {
        .Statistical-day .div-title, .Statistical-hour .div-title {
            margin-bottom: -10px !important;
        }
        .Map-Right {
            height: calc(60vh - 70px) !important;
        }
        .Map-Right-Container {
            height: calc(60vh - 71px) !important;
        }
        .Statistical-hour, .Statistical-day {
            margin: 10px 0;
            height: calc(20vh - 20px) !important;
            border: 2px solid;
            border-bottom: 4px solid;
            .pl-33 > p{
                
                font-size: 12px !important;
                padding-bottom: 3px !important;
            }
            .pl-33 > div {
                height: 20px !important;
                p {
                    font-size: 14px;
                }
            }
        }
    }

    @media (max-width: 1366px) {
        .list-chart {
            .chart-container {
                height: 280;
                .chart-item {
                    padding: 0 20px;
                    height: 260px;
                }
            }
        }
        .Statistical-day .div-title, .Statistical-hour .div-title {
            margin-bottom: -15px !important;
        }
        .Map-Right {
            height: calc(55vh - 70px) !important;
        }
        .Map-Right-Container {
            height: calc(55vh - 71px) !important;
        }
        .Statistical-hour, .Statistical-day {
            margin: 10px 0;
            height: calc(23vh - 20px) !important;
            border: 2px solid;
            border-bottom: 4px solid;
            .pl-33 > p{
                
                font-size: 12px !important;
                padding-bottom: 3px !important;
            }
            .pl-33 > div {
                height: 20px !important;
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

///////////
.css-1l7c0cy {
    padding-top: 10%;
}


@media (min-width: 1200px) {

    .Statistical-day,
    .Statistical-hour {
        .title {
            font-size: 16px !important;
        }
    }

    .Camera {
        .title {
            font-size: 16px;
        }

        height: 92vh !important;
    }

    .Chart-Right {
        .title {
            font-size: 16px;
        }

        height: 46vh !important;
    }

    .Map-Right {
        height: 46vh !important;
    }

    .Map-Right-Container {
        height: 45.4vh !important;

    }
}

@media (min-width: 1600px) {

    .Statistical-day,
    .Statistical-hour {
        .end .total {
            font-size: 33px !important;
        }

        .div-title {
            margin-bottom: 10px !important;
        }

        .title {
            font-size: 16px !important;
        }

        .item {
            margin-bottom: 5px;

            .label {
                padding-bottom: 3px !important;
            }

            div {
                height: 20px !important;

                span {
                    font-size: 15px !important;
                }
            }
        }

    }

    .Camera {
        height: 93.2vh
    }

    .Chart-Right {
        .title {
            font-size: 16px;
        }

        height: 46.4vh;
    }

    .Map-Right {
        height: 46.2vh;
    }

    .Map-Right-Container {
        height: 45.5vh !important;

    }
}

@media (min-width: 1800px) {
    .Camera {
        .top {
            height: 7%;
        }

        .content {
            height: 88%;
        }

        height: 94.3vh;
    }

    .Map-Right {
        height: 46vh;
    }

    .Map-Right-Container {
        height: 45.6vh !important;

    }

    .Chart-Right {
        .title {
            font-size: 16px;
        }

        height: 47vh;
    }

    .Map-Right {
        height: 46.8vh;
    }
}