.table-listdata {
    height: 83vh;
}

.reports-chart {
    width: 100%;
    height: 94.6vh;
    overflow: hidden;
    border-radius: 5px;
}

.reports-table {
    height: 98.6vh;
    overflow: hidden;
    border-radius: 5px;
}

.table-reportdata {
    height: 83vh;
}

.function-buttons {
    padding-left: 13%;
}

.ListDataDOT {
    .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
        margin: 0;
        font-size: 12px;
    }
    .list-chart {
        height: calc(50vh - 35px);
        width: 40vw;
        margin: auto;
        margin-bottom: 20px;
    }
    .chart-item {
        margin: auto;
        width: 600px;
    }
    .data-table {
        padding-top: 20px;
        height: calc(100vh - 67px);
        overflow: auto;
        table {
            th, td {
                font-size: 11px;
                padding: 15px 5px;
            }
        }
    }
}

@media only screen and (min-width: 200px) {

    .reports-table{
        height: 98.6vh !important;
    }
}

@media only screen and (min-width: 380px) {

    .reports-table{
        height: 96.6vh !important;
    }
}

@media only screen and (min-width: 400px) {

    .reports-table{
        height: 94.6vh !important;
    }
}

@media only screen and (min-width: 500px) {

    .reports-table{
        height: 98.6vh !important;
    }
}
@media only screen and (min-width: 700px) {

    .reports-table{
        height: 93.6vh !important;
    }
}

@media only screen and (min-width: 900px) {
    .reports-table{
        height: 91.6vh !important;
    }
}

@media only screen and (min-width: 1000px) {

    .reports-table{
        height: 100.6vh !important;
    }
}

@media only screen and (min-width: 1200px) {

    .reports-table{
        height: 94.6vh !important;
    }
}

@media only screen and (min-width: 1366px) {
    .table-listdata {
        height: 71vh;
    }
    .table-reportdata {
        height: 79vh;
    }
    .function-buttons {
        padding-left: 4%;
    }
    .reports-chart{
        height: 92vh;
    }
    .reports-table{
        height: 92vh;
    }
    .func-button{
        width: 100%;
        padding-right: 2%;
    }
}
@media only screen and (min-width: 1600px) {
    .function-buttons{
        width: 100%;
    }
    .table-listdata{
        height: 76vh;
    }
    .table-reportdata{
        height: 81vh;
    }
    .reports-table{
        height: 93vh;
    }
    .reports-chart{
        height: 93vh;
    }
    .func-button{
        width: 100%;
        padding-right: 3%;
    }
    .paper-listData{
        height: 93vh;
    }
}

@media only screen and (min-width: 1920px) {
    .paper-listData{
        height: 94vh;
    }
    .table-listdata {
        height: 79vh;
    }
    .table-reportdata {
        height: 82vh;
    }
    .table-reportdata {
        height: 83vh;
    }
    .function-buttons {
        padding-left: 13%;
    }
    .reports-table{
        height: 94vh;
    }
    .reports-chart{
        height: 94vh;
    }
}

@media (min-width: 600px){
    .css-14l925v {
        width: calc(100% - 250px) !important;
    }
}