.Help {
    background-color: white;
    height: calc(100vh - 60px);
    overflow: auto;

    .top {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid;

        .left {
            width: 50%;

            .image {
                text-align: center;

                img {
                    width: 80%;
                }
            }

            .info {
                display: flex;

                .content {
                    width: 40%;
                    text-align: center;

                    .value {
                        color: #4285F4;
                        font-weight: bold;
                        font-size: 20px;
                        margin: 0;
                    }

                    .label {
                        color: #EA4335;
                        font-size: 14px;
                    }
                }
            }
        }

        .right {
            width: 50%;

            .current {
                .title {
                    // color: #606060;
                    font-size: 18px;
                    text-align: center;
                    font-weight: bold;
                }

                .content {
                    display: flex;
                    justify-content: center;
                    .item {
                        width: 35%;
                        text-align: center;
                        border-left: 1px dashed #C4C4C4;

                        .value {
                            color: #34A853;
                            font-size: 22px;
                            font-weight: bold;
                            margin: 0;
                        }

                        .label {
                            color: #4285F4;
                            font-size: 12px;
                            margin: 0;
                        }
                    }
                }
            }

            .hypothesis {
                .note {
                    display: flex;

                    p {
                        width: 50%;
                        text-align: center;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }

                .title {
                    // color: #606060;
                    font-size: 18px;
                    text-align: center;
                    font-weight: bold;
                }

                .content {
                    display: flex;
                    padding-bottom: 8px;

                    .item {
                        width: 50%;
                        display: flex;

                        .label {
                            color: white;
                            margin: 0;
                            padding: 5px 10px;
                            background-color: #00b0f0;
                            width: 71%;
                            margin-right: 2%;
                        }

                        .value {
                            text-align: center;
                            color: white;
                            background-color: #00b0f0;
                            width: 25%;
                            font-size: 18px;
                            margin: 0;
                            border-radius: 0;
                        }

                        button {
                            background-color: #4CAF50;
                            border: none;
                            cursor: pointer;
                            color: white;
                            border-radius: 4px;
                            padding: 0 25px;
                        }
                    }
                }
                .sflood {
                    text-align: center;
                    font-weight: bold;
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }

    .bot {
        display: flex;

        .left {
            width: 50%;

            .title {
                text-transform: uppercase;
                padding-top: 15px;
                // color: #606060;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                padding-bottom: 10px;
            }

            .water-level-input {
                display: flex;
                p {
                    padding-left: 30px;
                    line-height: 40px;
                    padding-right: 10px;
                    margin: 0;
                    font-weight: bold;
                }

                input {
                    width: 85px;
                    border-radius: 0;
                    font-size: 25px;
                    margin: 0 5px;
                    font-weight: bold;
                    text-indent: 2px;
                    text-align: center;
                }

                span {
                    line-height: 40px;
                    font-weight: bold;
                }
            }

            .content {
                padding-left: 20px;

                .item {
                    padding-bottom: 35px;

                    .label {
                        font-weight: bold;
                        color: #34A853;
                    }

                    .input {
                        display: flex;

                        .luu-luong {
                            width: 55%;
                            color: #4285F4;
                            padding-left: 30px;

                            input {
                                width: 85px;
                                border-radius: 0;
                                font-size: 25px;
                                margin: 0 5px;
                                text-align: center;
                                font-weight: bold;
                                text-indent: 2px;
                            }
                        }

                        .do-mo-van {
                            width: 45%;
                            color: #4285F4;

                            input {
                                width: 75px;
                                border-radius: 0;
                                font-size: 25px;
                                margin: 0 5px;
                                font-weight: bold;
                                text-align: center;
                                text-indent: 2px;
                            }
                        }
                    }
                }

            }
        }

        .right {
            width: 50%;

            .title {
                text-transform: uppercase;
                padding-top: 15px;
                // color: #606060;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                padding-bottom: 10px;
            }

            padding-left: 20px;

            .water-level-input {
                display: flex;

                p {
                    padding-left: 30px;
                    line-height: 40px;
                    padding-right: 5px;
                    margin: 0;
                    font-weight: bold;
                }

                input {
                    width: 85px;
                    border-radius: 0;
                    font-size: 25px;
                    margin: 0 5px;
                    font-weight: bold;
                    text-indent: 2px;
                    text-align: center;
                }

                span {
                    line-height: 40px;
                    font-weight: bold;
                }
            }
            .warningZtl_title{
                margin-top: 20px;
            }
            .warningZtl{
                display: flex;
                .label_warningZtl{
                    color: #4285F4;
                    padding-left: 15px;
                }
                p {
                    padding-left: 30px;
                    line-height: 40px;
                    padding-right: 5px;
                    margin: 0;
                    font-weight: bold;
                }

                input {
                    width: 85px;
                    border-radius: 0;
                    font-size: 25px;
                    margin: 0 5px;
                    font-weight: bold;
                    text-indent: 2px;
                    text-align: center;
                }

                span {
                    line-height: 40px;
                    font-weight: bold;
                }
            }
            .item {
                padding-bottom: 35px;

                .label {
                    font-weight: bold;
                    color: #34A853;
                }

                .input {
                    display: flex;

                    .luu-luong {
                        width: 55%;
                        color: #4285F4;
                        padding-left: 30px;

                        input {
                            width: 85px;
                            border-radius: 0;
                            font-size: 25px;
                            margin: 0 5px;
                            font-weight: bold;
                            text-align: center;
                            text-indent: 2px;
                        }
                    }

                    .do-mo-van {
                        width: 45%;
                        color: #4285F4;

                        input {
                            width: 75px;
                            border-radius: 0;
                            font-size: 25px;
                            margin: 0 5px;
                            font-weight: bold;
                            text-align: center;
                            text-indent: 2px;
                        }
                    }
                }
            }
        }
    }
}

.HelpAudio {
    .audio{
        font-size: 32px;
        padding-top: 20px;
        padding-bottom: 40px;
        padding-left: 20px;
    }
    .item {
        display: flex;
        padding-left: 50px;
        align-items: center;
        padding-bottom: 30px;
        // .item-btn {
        //     min-width: 200px;
        //     text-align: right;
        // }
        button {
            color: white;
            padding: 10px;
            border-radius: 5px;
            background-color: #EA4335;
            font-weight: bold;
        }
        .title {
            padding-left: 20px;
        }
    }
    
}

@media only screen and (max-width: 1200px) { 
    .Help{
        .top{
            display: block;
            .left, .right{
                width: 100% !important;
            }
            .right{
                margin-bottom: 20px;
            }
        }
        .bot{
            display: block;
            .left{
                width: 100%;
                border-bottom: 1px solid;
            }
            .right{
                width: 100%;
                
                .warningZtl{
                    display: block;
                    padding-left: 15px;
                    .label_warningZtl{
                        padding-left: 0;
                    }
                }
            }
        }
        
    }
}


@media only screen and (max-width: 1500px) {
    .Help .top {
        padding: 0;

        .left {
            padding-top: 20px;
        }

        .right {
            .current {
                .title {
                    padding-bottom: 0;
                }

                .content {
                    padding-top: 0;
                }
            }
        }
    }

    .Help .bot {
        .left {
            .title {
                padding: 0;
            }

            .content .item {
                padding-bottom: 10px;

                .input {
                    .do-mo-van {
                        width: 48%;
                    }

                    .luu-luong {
                        width: 52%;
                        padding-left: 15px;
                    }
                }
            }

        }

        .right {
            .title {
                padding: 0;
            }

            .content .item {
                padding-bottom: 10px;

                .input {
                    .do-mo-van {
                        width: 48%;
                    }

                    .luu-luong {
                        width: 52%;
                        padding-left: 15px;
                    }
                }
            }
        }
    }

}