.Map-Right-Container {
    min-height: 300px;
    .leaflet-popup-content {
        min-width: 170px;
        max-height: 160px;
        overflow: auto;
        .title {
            color: #1962a9;
            font-weight: 700;
            border-bottom: 1px gray solid;
        }
        .item {
            padding-top: 4px;
            line-height: 23px;
            .item-title {
                font-weight: bold;
            }
        }
    }

    .toggle-icon {
        position: absolute;
        right: 0;
        background: white;
        z-index: 999;
        margin-right: 11px;
        width: 45px;
        height: 44px;
        margin-top: 70px;
        border-radius: 3px;
        .toggle-icon-menu {
            display: none;
        }
        .toggle-icon-menu-item {
            display: flex;
            input {
                margin: 0 2px;
            }
            p {
                margin: 0;
            }
        }
        
        &:hover {
            .toggle-icon-menu {
                padding: 3px;
                border-radius: 3px;
                position: absolute;
                right: 0;
                top: 0;
                width: 175px;
                height: 80px;
                background-color: white;
                display: block;
                color: #333;
                font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
            }
        }  
    }
}
